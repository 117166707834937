body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfefc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/*Navbar*/
.navbar
{
  background-color: #fff;
  min-height: 70px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

/* animation*/

.text1, .text2, .text3{
  font-family: 'Ubuntu', sans-serif;
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.8rem;
  font-weight: bold;
}

.text1{
  animation: bsj 1s infinite;
  color: #0076CE;
}

.text2{
  animation: bsj 2s infinite;
  color: #cc0000;
}

.text3{
  animation: bsj 1s infinite;
  color: #004225;
}

@keyframes bsj {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.BiznieLogo
{
  width: 30%;
  height: auto;
}

.coming
{
    font-family: 'Times New Roman', Times, serif;
    font-size: 2rem;
    font-weight:600;
    font-style: italic;
    letter-spacing: 1px;
    background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #0039a6 67%,
    #cc0000 100%
  );
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  animation-direction: reverse;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

/* Homepage.js */

.container
{
  margin-top: 110px;
}

.card{
  background-color: transparent;
}

.card-body .card-title
{
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4.5rem;
  font-weight: bolder;
  letter-spacing: 1px;
  color: #cc0000;
}

.card-body .card-text
{
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 1px;
    font-size: 1.3rem;
}

.Gif
{
    width:70%;
    height: auto;
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    animation: backTop 3s ease-in-out infinite;
    transition: all .5s ease-in-out;
}

@keyframes backTop {
  0%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(0px);
  }
}

@media screen and (max-width:768px)
{
  .navbar{
    display: flow-root;
  }
  .navbar-toggler{
    float: right;
  }
  .navbar-nav{
    margin-top: 5px;
  }
  .navbar-nav li{
    padding-left: 5px;
  }
  .container{
    margin-top: 80px;
  }
  .card-body{
    text-align: center;
  }
  .card-body .card-title{
    font-size: 3.5rem;
  }
  .card-body .card-text{
    font-size: 1.3rem;
  }
  .Gif{
    margin-top: 0px;
  }
  .bizBtn{
    margin-top: 3.5rem !important;
    margin-bottom: 3rem !important;
  }
  .text1, .text2, .text3{
    font-size: 1.7rem;
    animation: none;
  }
}

.join-btn:hover{
  background-color: #0039a6;
  color: white;
}

.app-btn:hover{
  background-color: green;
  color: white;
}

.footer
{
  min-height: auto;
  margin-top: 50px;
  margin-bottom: 0px;
  background-color: #F9F6EE;
}

.footer .row
{
  padding-top: 20px;
}

.list-TC
{
  list-style-type: none;
  justify-content: center;
}

.list-TC li a
{
  text-decoration: none;
}

.listIcon
{
  list-style-type: none;
  justify-content: center;
}


.sm-icon
{
    width:auto;
    height:30px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 768px) {
  .list-TC, .listIcon{
    margin-right: 25px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .list-TC .num{
    margin-left: 0px !important;
  }

  .footer{
    margin-top: 0px;
  }

}

.cont-icon{
  width: 8%;
  margin-right: 5px;
}

.form-control{
  background: transparent;
}

.form-control::placeholder{

  font-family:Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

@media only screen and (max-width: 768px) {
  .container .contact{
    margin-top: 90px;
    margin-bottom: 20px;
  }
} 


/*About us */

.container .about-content{
  text-align: justify;
}